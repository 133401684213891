import {
  LockOutlined,
  Logout,
  Public,
} from '@mui/icons-material';
import {
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/store';
import {
  makeUserProtected,
  makeUserPublic,
  selectServiceUser,
} from './authSlice';

const UserProtectedButton = () => {
  const dispatch = useAppDispatch();
  const serviceUser = useSelector(selectServiceUser);

  if (serviceUser === null) {
    return (
      <Button
        startIcon={<Logout />}
        variant="outlined"
        disabled
      >
        未認証
      </Button>
    );
  }
  if (serviceUser.user.visibility === 'private') {
    return (
      <Button
        startIcon={<LockOutlined />}
        variant="outlined"
        disabled
      >
        非公開
      </Button>
    );
  }
  if (serviceUser.visibility === 'private') {
    return (
      <Button
        onClick={() => dispatch(makeUserPublic())}
        startIcon={<LockOutlined />}
        variant="outlined"
      >
        非公開
      </Button>
    );
  }
  return (
    <Button
      onClick={() => dispatch(makeUserProtected())}
      startIcon={<Public />}
      variant="contained"
    >
      公開
    </Button>
  );
};

export default UserProtectedButton;
