import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';

import { store, history } from './app/store';
import { AuthProvider } from './features/auth/AuthContext';
import TopPage from './features/top-page/TopPage';
import NavBar from './features/app-bar/NavBar';
import FavoriteListPage from './features/favorites/FavoriteListPage';
import TermOfServicePage from './features/term-of-service/TermOfServicePage';
import PrivacyPolicyPage from './features/privacy-policy/PrivacyPolicyPage';
import ContactPage from './features/contact/ContactPage';
import NotFoundPage from './features/error-page/NotFoundPage';
import SettingPage from './features/settings/SettingPage';
import CookieConsentPopUp from './features/privacy-policy/CookieConsentPopUp';
import UserSearchPage from './features/user-search/UserSearchPage';
import ScreenViewEventLogger from './features/privacy-policy/ScreenViewEventLogger';
import PrivacyProvider from './features/privacy-policy/PrivacyContext';

const App = () => {
  let time = new Date().getTime();
  const setActivityTime = () => {
    time = new Date().getTime();
  };
  document.body.addEventListener('mousemove', setActivityTime);
  document.body.addEventListener('click', setActivityTime);
  document.body.addEventListener('mouseup', setActivityTime);
  document.body.addEventListener('mousedown', setActivityTime);
  document.body.addEventListener('keydown', setActivityTime);
  document.body.addEventListener('keypress', setActivityTime);
  document.body.addEventListener('keyup', setActivityTime);
  document.body.addEventListener('submit', setActivityTime);
  document.body.addEventListener('change', setActivityTime);
  document.body.addEventListener('mouseenter', setActivityTime);
  document.body.addEventListener('scroll', setActivityTime);
  document.body.addEventListener('resize', setActivityTime);
  document.body.addEventListener('dblclick', setActivityTime);

  const refresh = () => {
    if (new Date().getTime() - time >= 60 * 60 * 1000) {
      window.location.reload();
    } else {
      setTimeout(refresh, 10 * 1000);
    }
  };

  setTimeout(refresh, 10 * 1000);
  return (
    <Provider store={store}>
      <AuthProvider>
        <PrivacyProvider>
          <ConnectedRouter history={history}>
            <Router>
              <NavBar />
              <Routes>
                <Route path="*" element={<NotFoundPage />} />

                <Route
                  path="/"
                  element={<TopPage />}
                />
                <Route path="/users" element={<UserSearchPage />} />
                <Route path="/users/:screenName" element={<FavoriteListPage />} />
                <Route path="/term-of-service" element={<TermOfServicePage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/settings" element={<SettingPage />} />
              </Routes>
              <CookieConsentPopUp />
              <ScreenViewEventLogger />
            </Router>
          </ConnectedRouter>
        </PrivacyProvider>
      </AuthProvider>
    </Provider>
  );
};

export default App;
