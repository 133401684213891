import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class Repository {
  private static connection(): AxiosInstance {
    if (process.env.NODE_ENV === 'production') {
      return axios.create({
        baseURL: 'https://favsearch.net/api/v1',
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
        xsrfCookieName: '_csrf_token',
        xsrfHeaderName: 'X-CSRF-Token',
      });
    }
    return axios.create({
      // cookieの問題が未解決
      // baseURL: 'https://tweet-store-staging-dot-libalexandria.an.r.appspot.com/api/v1',
      baseURL: 'http://localhost:8080/api/v1',
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      xsrfCookieName: '_csrf_token',
      xsrfHeaderName: 'X-CSRF-Token',
    });
  }

  public static async get<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
    const connection = this.connection();
    const response: AxiosResponse<T> = await connection.get<T>(path, config);
    return response.data;
  }

  public static async post<T>(path: string, data: any, config?: AxiosRequestConfig): Promise<T> {
    const connection = this.connection();
    const response: AxiosResponse<T> = await connection.post<T>(path, data, config);
    return response.data;
  }

  public static async delete<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
    const connection = this.connection();
    const response: AxiosResponse<T> = await connection.delete<T>(path, config);
    return response.data;
  }
}

export default Repository;
