import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { analytics } from '../../firebase';
import { selectAnalysysCookieConsent } from './privacySlice';

const ScreenViewEventLogger = () => {
  const location = useLocation();

  const analysisCookieConsent = useSelector(selectAnalysysCookieConsent);

  useEffect(() => {
    if (analysisCookieConsent) {
      logEvent(
        analytics,
        'screen_view',
        {
          firebase_screen: location.pathname + location.search + location.hash,
          firebase_screen_class: 'MainActivity',
        },
      );
    }
  }, [location]);
  return (<div />);
};

export default ScreenViewEventLogger;
