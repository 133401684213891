type TimestampProps = {
  unixTimestamp: number,
}

const TwitterDate = (props: TimestampProps) => {
  const { unixTimestamp } = props;
  const systemDate = new Date(unixTimestamp * 1000);
  const userDate = new Date();
  const diff = Math.floor((userDate.getTime() - systemDate.getTime()) / 1000);
  let content;
  if (diff <= 1) {
    content = 'just now';
  } else if (diff < 20) {
    content = `${diff} seconds ago`;
  } else if (diff < 40) {
    content = 'half a minute ago';
  } else if (diff < 60) {
    content = 'less than a minute ago';
  } else if (diff <= 90) {
    content = 'one minute ago';
  } else if (diff <= 3540) {
    content = `${Math.round(diff / 60)} minutes ago`;
  } else if (diff <= 5400) {
    content = '1 hour ago';
  } else if (diff <= 86400) {
    content = `${Math.round(diff / 3600)} hours ago`;
  } else if (diff <= 129600) {
    content = '1 day ago';
  } else if (diff < 604800) {
    content = `${Math.round(diff / 86400)} days ago`;
  } else if (diff <= 777600) {
    content = '1 week ago';
  } else {
    content = `on ${systemDate.getFullYear()}/${systemDate.getMonth() + 1}/${systemDate.getDate()}`;
  }
  return (
    <span>{content}</span>
  );
};

export default TwitterDate;
