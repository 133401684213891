import { Favorite } from '../domain/entity/favorite';
import { Medium } from '../domain/entity/medium';
import { ServiceUser } from '../domain/entity/service_user';
import { Tweet } from '../domain/entity/tweet';
import { OpenGraph, Url } from '../domain/entity/url';
import { User } from '../domain/entity/user';

/* eslint-disable camelcase */
export type FavoriteProtocol = {
  status: TweetProtocol;
  user: UserProtocol;
  private: boolean;
};

export type TweetProtocol = {
  id_str: string;
  text: string;
  user: UserProtocol;
  media: MediumProtocol[];
  urls: UrlProtocol[];
  created_at: number;
};

export type UserProtocol = {
  id: number;
  name: string;
  screen_name: string;
  profile_image_url: string;
  description: string;
  protected: boolean;
};

export type ServiceUserProtocol = {
  user: UserProtocol;
  created_at: number;
  updated_at: number;
  protected: boolean;
}

export type MediumProtocol = {
  url: string;
  display_url: string
  expanded_url: string
  media_url: string
};

export type UrlProtocol = {
  url: string
  display_url: string
  expanded_url: string
  open_graph: OpenGraphProtocol | null
};

export type OpenGraphProtocol = {
  title: string
  type: string
  url: string
  image_url: string
  image_height: number
  image_width: number
  description: string
  locale: string
  site_name: string
}

export type VisibilityProtocol = {
  user_protected: boolean;
};

export function userProtocolToUser(userProtocol: UserProtocol): User {
  return {
    id: userProtocol.id,
    name: userProtocol.name,
    screenName: userProtocol.screen_name,
    profileImageURL: userProtocol.profile_image_url,
    description: userProtocol.description,
    visibility: userProtocol.protected ? 'private' : 'public',
  };
}

export function serviceUserProtocolToServiceUser(
  serviceUserProtocol: ServiceUserProtocol,
): ServiceUser {
  return {
    user: userProtocolToUser(serviceUserProtocol.user),
    createdAt: serviceUserProtocol.created_at,
    updatedAt: serviceUserProtocol.updated_at,
    visibility: serviceUserProtocol.protected ? 'private' : 'public',
  };
}

export function mediaProtocolToMedia(mediaProtocol: MediumProtocol[]): Medium[] {
  return mediaProtocol.map<Medium>((medium: MediumProtocol) => (
    {
      url: medium.url,
      displayUrl: medium.display_url,
      expandedUrl: medium.expanded_url,
      mediaUrl: medium.media_url,
    }
  ));
}

export function openGraphProtocolToOpenGraph(p: OpenGraphProtocol | null): OpenGraph | null {
  if (!p) {
    return null;
  }
  return {
    title: p.title,
    type: p.type,
    url: p.url,
    imageUrl: p.image_url,
    imageHeight: p.image_height,
    imageWidth: p.image_width,
    description: p.description,
    locale: p.locale,
    siteName: p.site_name,
  };
}

export function urlProtocolToUrl(urlList: UrlProtocol[]): Url[] {
  return urlList.map<Url>((url: UrlProtocol) => ({
    url: url.url,
    displayUrl: url.display_url,
    expandedUrl: url.expanded_url,
    openGraph: openGraphProtocolToOpenGraph(url.open_graph),
  }));
}

export function tweetProtocolToTweet(tweetProtocol: TweetProtocol): Tweet {
  return {
    id: tweetProtocol.id_str,
    text: tweetProtocol.text,
    user: userProtocolToUser(tweetProtocol.user),
    media: mediaProtocolToMedia(tweetProtocol.media),
    urls: urlProtocolToUrl(tweetProtocol.urls),
    createdAt: tweetProtocol.created_at,
  };
}

export function favoriteProtocolToFavorite(favoriteProtocol: FavoriteProtocol): Favorite {
  return {
    tweet: tweetProtocolToTweet(favoriteProtocol.status),
    user: userProtocolToUser(favoriteProtocol.user),
    private: favoriteProtocol.private,
  };
}
