import { Box, Container, Typography } from '@mui/material';

const NotFoundPage = () => (
  <Box component="section" m={2}>
    <Container maxWidth="sm" disableGutters>
      <Typography
        variant="h4"
      >
        ページが見つかりません
      </Typography>
    </Container>
  </Box>
);

export default NotFoundPage;
