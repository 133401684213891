export type Medium = {
  url: string;

  displayUrl: string;

  expandedUrl: string;

  mediaUrl: string;
}

export const compatibleMediaUrl = (medium: Medium): string => {
  if (medium.mediaUrl === '') {
    return medium.url;
  }
  return medium.mediaUrl;
};
