import {
  AlternateEmail,
  DescriptionOutlined,
  FavoriteBorder,
  PrivacyTip,
  Settings,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import {
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/store';
import {
  selectDrawerOpen,
  selectServiceUser,
  toggleDrawerState,
} from '../auth/authSlice';

const DrawerMenu = () => {
  const drawerWidth = 280;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const serviceUser = useSelector(selectServiceUser);
  const drawerOpen = useSelector(selectDrawerOpen);

  const handleNavigate = (uri: string) => {
    dispatch(toggleDrawerState());
    navigate(uri);
  };

  let contents = [];
  if (serviceUser) {
    contents.push({
      key: 'favorite-page',
      text: 'お気に入り',
      handlerFunc: () => handleNavigate(`/users/${serviceUser.user.screenName}`),
      icon: <FavoriteBorder />,
    });
  }
  contents = contents.concat(
    [
      {
        key: 'find-user',
        text: 'ユーザを探す',
        handlerFunc: () => handleNavigate('/users'),
        icon: <AlternateEmail />,
      },
      {
        key: 'settings',
        text: '設定とプライバシー',
        handlerFunc: () => handleNavigate('/settings'),
        icon: <Settings />,
      },
      {
        key: 'term-of-service',
        text: '利用規約',
        handlerFunc: () => handleNavigate('/term-of-service'),
        icon: <DescriptionOutlined />,
      },
      {
        key: 'privacy-policy',
        text: 'プライバシーポリシー',
        handlerFunc: () => handleNavigate('/privacy-policy'),
        icon: <PrivacyTip />,
      },
    ],
  );

  const drawerContent = contents.map((content) => (
    <ListItemButton key={content.key} onClick={content.handlerFunc}>
      <ListItemIcon>
        {content.icon}
      </ListItemIcon>
      <ListItemText>{content.text}</ListItemText>
    </ListItemButton>
  ));

  return (
    <Box
      component="nav"
      aria-label="mailbox folders"
    >
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={() => { dispatch(toggleDrawerState()); }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        <Toolbar variant="dense" />
        <Divider />
        <List>
          {drawerContent}
        </List>
      </Drawer>
    </Box>
  );
};

export default DrawerMenu;
