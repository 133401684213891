import {
  Box,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import UserSearchForm from './UserSearchForm';

const UserSearchPage = () => (
  <Grid
    container
    spacing={0}
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ minHeight: '90vh' }}
  >
    <Grid item xs={3}>
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          FavSearch
        </Typography>
        <Typography
          variant="h5"
          align="center"
          color="textSecondary"
          paragraph
        >
          アカウント名で検索することができます。
          <br />
          友達のお気に入りを検索して
          <br />
          見逃したツイートを見つけましょう。
        </Typography>
        <Box
          component="div"
        >
          <UserSearchForm />
        </Box>
      </Container>
    </Grid>
  </Grid>
);

export default UserSearchPage;
