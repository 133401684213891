import {
  Avatar,
  IconButton,
} from '@mui/material';
import {
  useSelector,
} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectServiceUser,
} from './authSlice';
import SignInButton from './SignInButton';
import SignOutButton from './SignOutButton';

const AuthButton = () => {
  const navigate = useNavigate();
  const serviceUser = useSelector(selectServiceUser);

  if (serviceUser !== null) {
    return (
      <>
        <IconButton
          onClick={() => { navigate(`/users/${serviceUser.user.screenName}`); }}
          color="inherit"
        >
          <Avatar
            alt="profile image"
            src={serviceUser.user.profileImageURL || ''}
          />
        </IconButton>
        <SignOutButton />
      </>
    );
  }
  return (
    <SignInButton color="inherit" />
  );
};

export default AuthButton;
