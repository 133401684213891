import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type AppBarState = {
  drawerOpen: boolean,
  error: string | null,
};

const initialState: AppBarState = {
  drawerOpen: false,
  error: null,
};

export const selectDrawerOpen = (state: RootState) => state.appBar.drawerOpen;

const appBarSlice = createSlice({
  name: 'appBar',
  initialState,
  reducers: {
    setError: (state: AppBarState, action: PayloadAction<string>): void => {
      state.error = action.payload;
    },
    toggleDrawerState: (state: AppBarState): void => {
      state.drawerOpen = !state.drawerOpen;
    },
  },
});

export const {
  setError,
  toggleDrawerState,
} = appBarSlice.actions;

export default appBarSlice.reducer;
