import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Url } from '../../domain/entity/url';

type UrlProps = {
  url: Url
}

const OpenGraphCard = ({ url }: UrlProps) => {
  const openGraph = url.openGraph || {
    title: null,
    imageUrl: null,
    siteName: null,
    url: null,
    description: null,
  };

  return (
    <Card>
      <CardActionArea href={url.expandedUrl}>
        {openGraph.imageUrl
          && (
            <CardMedia
              component="img"
              image={openGraph.imageUrl}
              alt="画像が取得できませんでした"
              loading="lazy"
            />
          )}
        <CardContent>
          {(openGraph.siteName || openGraph.url || url.expandedUrl)
            && (
              <Typography color="text.secondary">
                {openGraph.siteName || openGraph.url || url.expandedUrl}
              </Typography>
            )}
          {openGraph.title
            && (
              <Typography variant="h6" component="div">
                {openGraph.title}
              </Typography>
            )}
          {openGraph.description
            && (
              <Typography variant="body2">
                {openGraph.description.length <= 100 ? openGraph.description : `${openGraph.description.substring(0, 100)}...`}
              </Typography>
            )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default OpenGraphCard;
