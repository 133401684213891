import { Delete } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { signOut } from 'firebase/auth';
import { useState } from 'react';
import { useAppDispatch } from '../../app/store';
import { auth } from '../../firebase';
import { deleteUser, setError } from './authSlice';

const DeleteUserButton = () => {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteUser = () => {
    handleClose();
    signOut(auth)
      .then(() => {
        dispatch(deleteUser());
      })
      .catch(() => {
        dispatch(setError('サインアウトに失敗しました'));
      });
  };

  return (
    <>
      <Button
        onClick={() => handleClickOpen()}
        startIcon={<Delete />}
        color="error"
        variant="contained"
      >
        削除
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          保存されている情報を削除します
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            FavSearchに保存されているユーザ情報、お気に入り情報を削除します。
            Twitterのツイート、お気に入りなどに影響を与えることはありません。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button onClick={handleDeleteUser} autoFocus color="error">
            データを削除する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUserButton;
