import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type CoookieDescriptions = {
  type: string
  name: string
  purpose: string | JSX.Element
  basis: string
  expire: string
  owner: string
};

type Props = {
  desc: CoookieDescriptions
};

const CookieDescription = (props: Props) => {
  const { desc } = props;
  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={4} p={1}>
          クッキー名
        </Grid>
        <Grid item xs={8} p={1}>
          {desc.name}
        </Grid>
        <Grid item xs={4} p={1}>
          クッキーのタイプ
        </Grid>
        <Grid item xs={8} p={1}>
          {desc.type}
        </Grid>
        <Grid item xs={4} p={1}>
          処理の根拠
        </Grid>
        <Grid item xs={8} p={1}>
          {desc.basis}
        </Grid>
        <Grid item xs={4} p={1}>
          クッキーの利用目的
        </Grid>
        <Grid item xs={8} p={1}>
          {desc.purpose}
        </Grid>
        <Grid item xs={4} p={1}>
          有効期間
        </Grid>
        <Grid item xs={8} p={1}>
          {desc.expire}
        </Grid>
        <Grid item xs={4} p={1}>
          クッキーの所有者
        </Grid>
        <Grid item xs={8} p={1}>
          {desc.owner}
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

const PrivacyPolicyPage = () => {
  const rows = [
    {
      type: '機能性クッキー',
      name: '_csrf_token',
      purpose: 'ご利用者のアカウントを第三者（多くの場合クラッカー）から守るためのセキュリティトークン',
      basis: '正当な権利(GDPR第 6条第1項(ｆ))',
      expire: '1日',
      owner: 'FavSearch',
    },
    {
      type: '機能性クッキー',
      name: 'sid',
      purpose: 'ログイン情報を保持するためのクッキー',
      basis: '正当な権利(GDPR第 6条第1項(ｆ))',
      expire: '5日',
      owner: 'FavSearch',
    },
    {
      type: '機能性クッキー',
      name: 'consent,consent_version,a_consent',
      purpose: '当該Cookie は Cookie コンプライアンスバナーで定義されています。 これは、訪問者が本サイトで使用されている Cookie の各カテゴリの使用への同意をするか、撤回するかを示します。 これにより、各カテゴリの Cookie が同意をしていないユーザーのブラウザに設定されるのを防止することができます。',
      basis: '正当な権利(GDPR第 6条第1項(ｆ))',
      expire: '360日',
      owner: 'FavSearch',
    },
    {
      type: '分析クッキー',
      name: 'bid',
      purpose: 'ご利用者がFavSearchを利用する傾向を分析し、サービスを改善するために関連したクッキー',
      basis: '同意 (GDPR第 6条第1項(a))',
      expire: '1年',
      owner: 'FavSearch',
    },
    {
      type: '分析クッキー',
      name: '_ga,_ga_GP8PGZXE67',
      purpose: 'ご利用者の本サービスの利用について Cookie が生成した情報は、Google が、保存先である米国にあるサーバーに転送します。 Google の Cookieについての詳細は、Googleのプライバシーポリシーをご覧ください。「設定とプライバシー」から同意を取り消すことができます。',
      basis: '同意 (GDPR第 6条第1項(a))',
      expire: '2年',
      owner: 'Google Inc.',
    },
    {
      type: 'マーケティングクッキー',
      name: '__ads',
      purpose: (
        <Typography variant="body1">
          第三者配信の広告サービス「Google Adsense グーグルアドセンス」で利用されるクッキーです。
          <Link href="https://policies.google.com/technologies/partner-sites">
            GOOGLE のサービスを使用するサイトやアプリから収集した情報の GOOGLE による使用
          </Link>
          からクッキーの利用について管理することができます。
        </Typography>
      ),
      basis: '同意 (GDPR第 6条第1項(a))',
      expire: '390日',
      owner: 'Google Inc.など第三者配信事業者',
    },
  ];

  const cookieContent = rows.map((row) => (<CookieDescription key={`cookie_${row.name}`} desc={row} />));

  return (
    <Box component="section">
      <Container maxWidth="sm" disableGutters>
        <Paper>
          <Box p={4}>
            <Box my={2}>
              <Typography
                variant="h3"
                component="h1"
              >
                プライバシーポリシー
              </Typography>
              <Typography
                variant="body1"
              >
                本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、
                以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
              </Typography>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  本プライバシーポリシーの適用範囲
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    本ポリシーは、本サービスのユーザによる個々のご利用及び本サービスを介した個人データの処理であって、
                    適用プライバシー法令が適用されるものに対して適用されます。
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    本サービスは、第三者の情報を含んでいる可能性があります（例として、ハイパーリンク、バナーなど）。
                    これら第三者の情報を管理しておらず、第三者による適用プライバシー法令の遵守について、当社は責任を負いません。
                    本サービスご利用者において、ご覧になる第三者のウェブサイトのプライバシーポリシーを、よくお読みくださいますよう、お願いいたします。
                  </Typography>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  個人データ
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    本サービスでは以下のデータを個人データを取得することがあります。
                  </Typography>
                  <ul>
                    <li>ユーザ名</li>
                    <li>ユーザ識別子</li>
                    <li>IPアドレス</li>
                    <li>位置情報</li>
                    <li>デバイス情報</li>
                    <li>訪問日時</li>
                    <li>ブラウザ情報</li>
                    <li>検索クエリ</li>
                  </ul>
                </Box>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    本プライバシーポリシーに記載されているとおり、適用プライバシー法令に従ってのみ、個人データを処理します。
                  </Typography>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  個人情報の収集方法
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    利用者が以下のことをする場合に、個人情報を収集します。
                  </Typography>
                  <ul>
                    <li>登録を行うとき</li>
                    <li>ウェブサイトを閲覧若しくは使用したとき</li>
                  </ul>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  個人情報を収集・利用する目的
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    本サービスでは以下の目的のみに個人データを取得し、処理します。
                  </Typography>
                  <Box m={2}>
                    <Typography
                      variant="body1"
                    >
                      本サービスのサービス及び広告の提供及び改善及び創出のため個人データを取得し、処理します。
                    </Typography>
                  </Box>
                  <Box m={2}>
                    <Typography
                      variant="body1"
                    >
                      本サービスはサービスについて連絡を取り合うため、アカウント及びご利用に関する重要な連絡を取り合うため、
                      正当な利益を得る目的のため、すなわち通常の業務を遂行するために個人データを利用します。
                    </Typography>
                  </Box>
                </Box>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    個人データを収集する際の処理目的以外の目的で、さらに個人データを処理しようとする場合、
                    その処理を行う前に、当該他の目的に関する情報及び全ての関連する追加情報を提供します。
                  </Typography>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  クッキー（COOKIES）
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    本サービスが正しく機能するようにクッキー（Cookie）を使用しています。
                  </Typography>
                </Box>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    クッキーは、ブラウザにより当社サイトご利用者のコンピュータ上に保存される情報です。
                    本サービスは、異なる目的のために、異なる種類のクッキーを使用しています。
                  </Typography>
                  <ul>
                    <li>
                      機能性クッキー（Functional cookies）:
                      当社サイトが正しく機能するために必要なクッキーであり、アカウントを作成するのに必要なクッキーも含みます
                    </li>
                    <li>
                      分析クッキー（Analytical cookies）:
                      当社サイトご利用者が当社サイト（の一部）をどのように使用されているかについての情報を得るためのクッキーです。
                      これにより、本サービスを改善し、本サービスご利用者にとっての関心事や重要事項にできる限り合致した内容にすることができます。
                      クッキーにより取得したデータを、当社サイトの利用状況を分析するためにのみ使用します。
                    </li>
                    <li>マーケティングクッキー: 関連するコンテンツ・広告を表示するためのクッキー</li>
                  </ul>
                </Box>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    本サービスが使用するクッキーの一部は、ブラウザでのセッション終了後、すなわちブラウザを閉じた後に削除されます。
                    その他のクッキーはご利用者のエンドデバイスに残り、お客様の次回訪問時にお客様のブラウザを認識することができるようになります。
                    さらに、本サービスで使用されているクッキーの一部は、第三者によって提供されています。
                    本ウェブサイトを訪問中に使用されるクッキーについては、複数の管理方法が存在します。
                    ご利用者は、ブラウザを設定することで、特定の場合または一般的に、クッキーの受け取りを拒否することができます。
                    または、すでに設定されているクッキーを削除することや個別に選択することも可能です。
                    ただし、本サービスでは、ウェブサイト上の一部の機能を適切に運用するために技術的に必要なクッキーを使用しているので、
                    これらのクッキーが受け入れられない場合は本サービスの機能が制限される場合があります。
                  </Typography>
                </Box>
                <Box my={2}>
                  <Typography
                    variant="h5"
                    component="h3"
                  >
                    利用するクッキー
                  </Typography>
                  {cookieContent}
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  個人データの共有
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
                    このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。
                    この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
                    この規約に関して、詳しくは
                    <Link href="https://marketingplatform.google.com/about/analytics/terms/jp/">
                      こちら
                    </Link>
                    をご覧下さい。
                  </Typography>
                  <Typography
                    variant="body1"
                  >
                    第三者配信の広告サービス「Google Adsense グーグルアドセンス」等を利用しています。
                    Googleなどの第三者配信事業者がCookieを使用して、ユーザが本サービスや他のウェブサイトの過去にアクセスした際の情報に基づいて広告を配信します。
                    Google が広告 Cookie を使用することにより、ユーザーがそのサイトや他のサイトにアクセスした際の情報に基づいて、
                    Google やそのパートナーが適切な広告をユーザーに表示できます。
                    <Link href="https://www.google.com/settings/ads">
                      広告設定
                    </Link>
                    でパーソナライズ広告を無効にできます。
                    また、
                    <Link href="www.aboutads.info">
                      www.aboutads.info
                    </Link>
                    にアクセスすれば、パーソナライズ広告に使われる第三者配信事業者の Cookie を無効にできます。
                  </Typography>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  個人データの保存期間
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    本サービスは、特定の処理の目的のために必要な期間に限り、ご利用者の個人データを処理します。
                    原則として、お客様の個人データを、それぞれの処理目的を達成するために当該期間を超えて保管が必要な場合を除き、上記の目的のための処理に必要な期間中保管します。
                  </Typography>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  個人データの保護方法
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    データセキュリティを確保するため、特に、データを転送する際の脅威からご利用者の個人情報を保護するため、
                    また第三者が違法に情報を取得することを防ぐため、最新の技術的措置を維持しています。
                    これらの措置は、常に最新の技術水準に適合しています。
                  </Typography>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  ご利用者の権利
                </Typography>
                <Box m={2}>
                  <Typography
                    variant="body1"
                  >
                    ご利用者は、アクセス権、訂正権、消去権、処理の制限権およびデータポータビリティの権利を有しています。
                    ご利用者の同意に基づいて個人情報が処理される場合、ご利用者は、同意を撤回する権利を有します。
                    撤回は、ご本人の同意に基づく処理につき、将来のもののみに反映されることにご留意下さい。
                    個人データが、正当な利益のために処理される場合、ご利用者は、異議を申し立てる権利を有します。

                    上記の権利を行使する場合、「
                    <RouterLink to="/contact">問い合わせページ</RouterLink>
                    」に記載している問い合わせ先にご連絡下さい。
                    権利行使資格を確認するために、本人確認書類の提示を求めることがあります。
                    もしお客様が個人データの処理を違法であるとお考えの場合、監督官庁に不服を申し立てる権利があります。
                    この申立権は、他の行政上又は司法上の救済を何ら侵害するものではありません。
                  </Typography>
                </Box>
              </Box>
              <Box my={2}>
                <Typography
                  variant="h4"
                  component="h2"
                >
                  プライバシーポリシーの変更
                </Typography>
                <Box m={2}>
                  <ul>
                    <li>
                      本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，
                      ユーザーに通知することなく，変更することができるものとします。
                    </li>
                    <li>
                      変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default PrivacyPolicyPage;
