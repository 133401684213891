import { Box, Container, Paper } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContext';
import FavoriteList from './FavoriteList';
import LoadFavoritesButton from './LoadFavoritesButton';
import SearchForm from './SearchForm';

export const FavoriteListPage = () => {
  const params = useParams();
  const screenName = params.screenName || '';

  const [searchParams] = useSearchParams();
  const queryParam = searchParams.get('query') || '';

  const serviceUser = useAuthContext();

  let loadingButton;

  if (
    serviceUser !== null
    && serviceUser.user.screenName === screenName
  ) {
    loadingButton = <LoadFavoritesButton />;
  }

  return (
    <Box component="section" m={2}>
      <Container maxWidth="sm" disableGutters>
        <Paper>
          <SearchForm screenName={screenName} query={queryParam} />
          {loadingButton}
          <FavoriteList screenName={screenName} query={queryParam} />
        </Paper>
      </Container>
    </Box>
  );
};

export default FavoriteListPage;
