import { ServiceUser } from '../domain/entity/service_user';
import { Visibility } from '../domain/entity/visibility-status';
import {
  ServiceUserProtocol,
  serviceUserProtocolToServiceUser,
  VisibilityProtocol,
} from './protocol';
import Repository from './repository';

type ServiceUserResponseProtocol = {
  /* eslint-disable camelcase */
  service_user: ServiceUserProtocol
}

type ServiceUserLisrResponseProtocol = {
  /* eslint-disable camelcase */
  service_users: ServiceUserProtocol[]
}

export const UserRepository = {
  async getAuthInfo(): Promise<ServiceUser> {
    const response = await Repository.get<ServiceUserResponseProtocol>(
      '/users/auth',
    );
    const user = serviceUserProtocolToServiceUser(response.service_user);
    return user;
  },

  async postAuthInfo(
    userID: string,
    accessToken: string,
    accessSecret: string,
    userToken: string,
  ): Promise<ServiceUser> {
    const response = await Repository.post<ServiceUserResponseProtocol>(
      '/users/auth',
      {
        user_id: userID,
        access_token: accessToken,
        access_secret: accessSecret,
        user_token: userToken,
      },
    );

    return serviceUserProtocolToServiceUser(response.service_user);
  },

  async deleteAuthInfo(): Promise<{}> {
    await Repository.delete<void>(
      '/users/auth',
    );
    return {};
  },

  async deleteUser(): Promise<{}> {
    const result = await Repository.delete<{}>(
      '/users/delete',
    );

    return result;
  },

  async makeUserProtected(): Promise<Visibility> {
    const result = await Repository.post<VisibilityProtocol>(
      '/users/private',
      {},
    );

    return result.user_protected ? 'private' : 'public';
  },

  async makeUserPublic(): Promise<Visibility> {
    const result = await Repository.post<VisibilityProtocol>(
      '/users/public',
      {},
    );

    return result.user_protected ? 'private' : 'public';
  },

  async screenNameAutocomplete(screenName: string): Promise<ServiceUser[]> {
    const result = await Repository.get<ServiceUserLisrResponseProtocol>(
      '/users/screen-name/autocomplete',
      {
        params: {
          screen_name: screenName,
          size: 5,
        },
      },
    );

    return result.service_users.map(serviceUserProtocolToServiceUser);
  },
};

export default UserRepository;
