import React from 'react';
import {
  AppBar,
  createTheme,
  IconButton,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  SavedSearch,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import { indigo } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import {
  toggleDrawerState,
} from '../auth/authSlice';
import AuthButton from '../auth/AuthButton';
import DrawerMenu from './DrawerMenu';
import { useAppDispatch } from '../../app/store';

const NavBar = () => {
  const theme = createTheme({
    palette: {
      primary: indigo,
    },
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky">
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => { dispatch(toggleDrawerState()); }}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            edge="start"
            onClick={() => { navigate('/'); }}
            color="inherit"
            aria-label="menu"
          >
            <SavedSearch />
          </IconButton>
          <Typography
            component="div"
            variant="h6"
            color="inherit"
            style={{ textTransform: 'none' }}
            sx={{ flexGrow: 1 }}
          >
            <Link to="/" style={{ textDecoration: 'none', color: 'unset' }}>
              FavSearch
            </Link>
          </Typography>
          <AuthButton />
        </Toolbar>
      </AppBar>
      <DrawerMenu />
    </ThemeProvider>
  );
};

export default NavBar;
