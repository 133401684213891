import { Button } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { signInWithPopup, TwitterAuthProvider } from 'firebase/auth';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/store';
import { analytics, auth, provider } from '../../firebase';
import { usePrivacyContext } from '../privacy-policy/PrivacyContext';
import {
  getUser,
  selectGetUserStatus,
  selectServiceUser,
  selectSignInStatus,
  setError,
  signInUser,
} from './authSlice';

const SignInButton = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getUserStatus = useSelector(selectGetUserStatus);
  const signInStatus = useSelector(selectSignInStatus);
  const serviceUser = useSelector(selectServiceUser);

  const privacyStatus = usePrivacyContext();

  useEffect(() => {
    if (getUserStatus === 'idle') {
      dispatch(getUser());
    }
  }, [getUserStatus]);

  useEffect(() => {
    if (
      signInStatus === 'succeeded'
      && serviceUser !== null
    ) {
      navigate(`/users/${serviceUser.user.screenName}`);
    }
  }, [signInStatus, serviceUser !== null]);

  const handleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const { user } = result;
        user
          .getIdToken()
          .then((token) => {
            const credential = TwitterAuthProvider.credentialFromResult(result);
            if (credential === null) {
              return;
            }
            const userIDList = user
              .providerData
              .filter((userInfo) => userInfo.providerId === TwitterAuthProvider.PROVIDER_ID)
              .map((userInfo) => userInfo.uid);

            if (userIDList.length !== 1) {
              return;
            }

            if (credential.accessToken && credential.secret) {
              const authInfo = {
                userID: userIDList[0],
                accessToken: credential.accessToken,
                accessSecret: credential.secret,
                userToken: token,
              };
              dispatch(signInUser(authInfo));

              if (privacyStatus && privacyStatus.analysis) {
                logEvent(analytics, 'login');
              }
            }
          })
          .catch(() => { setError('ログインに失敗しました'); });
      }).catch(() => {
        dispatch(setError('ログインに失敗しました'));
      });
  };

  if (getUserStatus === 'idle' || getUserStatus === 'loading') {
    return (
      <Button
        color="inherit"
        disabled
      >
        Loading...
      </Button>
    );
  }
  if (signInStatus === 'loading') {
    return (
      <Button
        color="inherit"
        disabled
      >
        Sign In中
      </Button>
    );
  }
  return (
    /* eslint-disable react/jsx-props-no-spreading */
    <Button
      onClick={handleSignIn}
      {...props}
    >
      Twitterでログインする
    </Button>
  );
};

export default SignInButton;
