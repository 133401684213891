import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserRepository from '../../api/userRepository';
import { AppDispatch, RootState } from '../../app/store';
import { ApiStatus } from '../../domain/entity/api-status';
import { ServiceUser } from '../../domain/entity/service_user';
import { Visibility } from '../../domain/entity/visibility-status';

export type AuthState = {
  serviceUser: ServiceUser | null,
  drawerOpen: boolean,
  getUserStatus: ApiStatus,
  signInStatus: ApiStatus,
  signOutStatus: ApiStatus,
  deleteStatus: ApiStatus,
  userProtectedStatus: ApiStatus,
  error: string | null,
};

const initialState: AuthState = {
  serviceUser: null,
  drawerOpen: false,
  getUserStatus: 'idle',
  signInStatus: 'idle',
  signOutStatus: 'idle',
  deleteStatus: 'idle',
  userProtectedStatus: 'idle',
  error: null,
};

export const selectServiceUser = (state: RootState) => state.auth.serviceUser;
export const selectSignInStatus = (state: RootState) => state.auth.signInStatus;
export const selectSignOutStatus = (state: RootState) => state.auth.signOutStatus;
export const selectGetUserStatus = (state: RootState) => state.auth.getUserStatus;
export const selectDrawerOpen = (state: RootState) => state.auth.drawerOpen;
export const selectDeleteStatus = (state: RootState) => state.auth.deleteStatus;

export type SignInInfo = {
  userID: string
  accessToken: string
  accessSecret: string
  userToken: string
}

export const getUser = createAsyncThunk<
  ServiceUser,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'users/auth/get',
  async () => {
    const response = await UserRepository.getAuthInfo();
    return response;
  },
);

export const signInUser = createAsyncThunk<
  ServiceUser,
  SignInInfo,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'users/auth/post',
  async (signInInfo) => {
    const response = await UserRepository.postAuthInfo(
      signInInfo.userID,
      signInInfo.accessToken,
      signInInfo.accessSecret,
      signInInfo.userToken,
    );
    return response;
  },
);

export const signOutUser = createAsyncThunk<
  {},
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'users/auth/delete',
  async () => {
    const response = await UserRepository.deleteAuthInfo();
    return response;
  },
);

export const deleteUser = createAsyncThunk<
  {},
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'users/delete',
  async () => {
    const response = await UserRepository.deleteUser();
    return response;
  },
);

export const makeUserProtected = createAsyncThunk<
  Visibility,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'users/protected',
  async () => {
    const response = await UserRepository.makeUserProtected();
    return response;
  },
);

export const makeUserPublic = createAsyncThunk<
  Visibility,
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'users/public',
  async () => {
    const response = await UserRepository.makeUserPublic();
    return response;
  },
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setError: (state: AuthState, action: PayloadAction<string>): void => {
      state.error = action.payload;
    },
    toggleDrawerState: (state: AuthState): void => {
      state.drawerOpen = !state.drawerOpen;
    },
    setDeleteStatus: (state: AuthState, action: PayloadAction<ApiStatus>): void => {
      state.deleteStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state): void => {
        state.getUserStatus = 'loading';
      })
      .addCase(
        getUser.fulfilled,
        (state, action) => {
          state.serviceUser = action.payload;
          state.getUserStatus = 'succeeded';
        },
      )
      .addCase(
        getUser.rejected,
        (state) => {
          state.getUserStatus = 'failed';
        },
      )
      .addCase(signInUser.pending, (state) => {
        state.signInStatus = 'loading';
      })
      .addCase(
        signInUser.fulfilled,
        (state, action) => {
          state.serviceUser = action.payload;
          state.signInStatus = 'succeeded';
        },
      )
      .addCase(
        signInUser.rejected,
        (state, action) => {
          state.signInStatus = 'failed';
          state.error = action.error.message || null;
        },
      )
      .addCase(signOutUser.pending, (state) => {
        state.signOutStatus = 'loading';
      })
      .addCase(
        signOutUser.fulfilled,
        (state) => {
          state.serviceUser = null;
          state.signOutStatus = 'succeeded';
        },
      )
      .addCase(
        signOutUser.rejected,
        (state, action) => {
          state.signOutStatus = 'failed';
          state.error = action.error.message || null;
        },
      )
      .addCase(deleteUser.pending, (state): void => {
        state.deleteStatus = 'loading';
      })
      .addCase(
        deleteUser.fulfilled,
        (state) => {
          state.serviceUser = null;
          state.deleteStatus = 'succeeded';
        },
      )
      .addCase(
        deleteUser.rejected,
        (state, action) => {
          state.deleteStatus = 'failed';
          state.error = action.error.message || null;
        },
      )
      .addCase(makeUserProtected.pending, (state): void => {
        state.userProtectedStatus = 'loading';
      })
      .addCase(
        makeUserProtected.fulfilled,
        (state, action) => {
          state.userProtectedStatus = 'idle';
          if (state.serviceUser !== null) {
            state.serviceUser.visibility = action.payload;
          }
        },
      )
      .addCase(
        makeUserProtected.rejected,
        (state, action) => {
          state.userProtectedStatus = 'failed';
          state.error = action.error.message || null;
        },
      )
      .addCase(makeUserPublic.pending, (state): void => {
        state.userProtectedStatus = 'loading';
      })
      .addCase(
        makeUserPublic.fulfilled,
        (state, action) => {
          state.userProtectedStatus = 'idle';
          if (state.serviceUser !== null) {
            state.serviceUser.visibility = action.payload;
          }
        },
      )
      .addCase(
        makeUserPublic.rejected,
        (state, action) => {
          state.userProtectedStatus = 'failed';
          state.error = action.error.message || null;
        },
      );
  },
});

export const {
  setError,
  toggleDrawerState,
  setDeleteStatus,
} = authSlice.actions;

export default authSlice.reducer;
