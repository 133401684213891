import { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  currentConsentVersion,
  selectCheckConsent,
  selectConsentVersion,
  selectHasConsent,
  setAnalysysCookieConsent,
  setCheckConsent,
  setConsentVersion,
} from './privacySlice';
import { useAppDispatch } from '../../app/store';

const CookieConsentPopUp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const consent = Cookies.get('consent');
  const consentVersion = Cookies.get('consent_version');
  const checkConsent = useSelector(selectCheckConsent);
  const hasConsentVersion = useSelector(selectConsentVersion) === null;

  useEffect(() => {
    if (checkConsent) {
      dispatch(setCheckConsent(consent === '1'));
    }
  }, [checkConsent]);

  useEffect(() => {
    if (hasConsentVersion) {
      dispatch(setConsentVersion(consentVersion || null));
    }
  }, [hasConsentVersion]);

  // google botの場合は同意したとみなす
  const isGoogleBot = navigator.userAgent.toLowerCase().indexOf('googlebot') > -1;

  useEffect(() => {
    if (isGoogleBot) {
      dispatch(setCheckConsent(true));
      dispatch(setConsentVersion(currentConsentVersion));
    }
  }, [isGoogleBot]);

  const hasConsent = useSelector(selectHasConsent);

  useEffect(() => {
    if (!hasConsent) {
      const analysisCookieConsent = Cookies.get('a_consent');
      dispatch(setAnalysysCookieConsent(analysisCookieConsent === '1'));
      dispatch(setCheckConsent(true));
    }
  }, [hasConsent]);

  const handleCookieManagement = () => {
    dispatch(setCheckConsent(true));
    dispatch(setConsentVersion(currentConsentVersion));
    navigate('/settings');
  };

  const handlePrivacyPolicy = () => {
    dispatch(setCheckConsent(true));
    dispatch(setConsentVersion(currentConsentVersion));
    navigate('/privacy-policy');
  };

  const handleAgree = () => {
    dispatch(setCheckConsent(true));
    dispatch(setConsentVersion(currentConsentVersion));
    dispatch(setAnalysysCookieConsent(true));
  };

  return (
    <Dialog
      open={!hasConsent}
      aria-describedby="cookie-consent-description"
    >
      <DialogTitle>クッキーの利用について</DialogTitle>
      <DialogContent>
        <DialogContentText id="cookie-consent-dialog-slide-description">
          FavSearchではクッキーを利用することで、サービスを提供しています。
          クッキーの利用方法については
          <Button variant="text" onClick={handlePrivacyPolicy}>プライバシーポリシー</Button>
          を参照してください。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCookieManagement}>クッキーの利用を制限する</Button>
        <Button onClick={handleAgree}>同意する</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsentPopUp;
