import { FavoriteListPage } from '../domain/entity/favorite-list';
import {
  FavoriteProtocol,
  favoriteProtocolToFavorite,
} from './protocol';
import { Repository } from './repository';

type FavoritePageProtocol = {
  /* eslint-disable camelcase */
  favorite_list: FavoriteProtocol[];
  has_next: boolean;
  page: number;
  size: number;
};

export const FavoriteRepository = {
  async getPublicFavoriteList(
    screenName: string,
    query: string = '',
    page: number = 0,
    size: number = 20,
  ): Promise<FavoriteListPage> {
    const favoriteListPage = await Repository.get<FavoritePageProtocol>(
      '/favorites/search',
      {
        params: {
          screen_name: screenName,
          query,
          page,
          size,
        },
      },
    );

    const favoriteList = favoriteListPage.favorite_list.map(favoriteProtocolToFavorite);

    return {
      favoriteList,
      hasNext: favoriteListPage.has_next,
      page: favoriteListPage.page,
      size: favoriteListPage.size,
    };
  },

  async getPrivateFavoriteList(
    query: string = '',
    page: number = 0,
    size: number = 20,
  ): Promise<FavoriteListPage> {
    const favoriteListPage = await Repository.get<FavoritePageProtocol>(
      '/favorites/search/private',
      {
        params: {
          query,
          page,
          size,
        },
      },
    );

    const favoriteList = favoriteListPage.favorite_list.map(favoriteProtocolToFavorite);

    return {
      favoriteList,
      hasNext: favoriteListPage.has_next,
      page: favoriteListPage.page,
      size: favoriteListPage.size,
    };
  },

  async fetchFavoriteList(): Promise<{}> {
    const result = await Repository.post<{}>(
      '/favorites/fetch',
      {},
    );
    return result;
  },
};

export default FavoriteRepository;
