import { initializeApp } from 'firebase/app';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { getAuth, TwitterAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCYJKXtJjSLfB437vs8h8kr7eZYzPi2v5Q',
  authDomain: 'libalexandria.firebaseapp.com',
  databaseURL: 'https://libalexandria.firebaseio.com',
  projectId: 'libalexandria',
  storageBucket: 'libalexandria.appspot.com',
  messagingSenderId: '308791680928',
  appId: '1:308791680928:web:7f126235212f6f22110ec5',
  measurementId: 'G-GP8PGZXE67',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const userLang = navigator.language;

export const auth = getAuth(app);
auth.languageCode = userLang;

export const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, false);

export const enableAnalytics = () => {
  analytics.app.automaticDataCollectionEnabled = true;
  setAnalyticsCollectionEnabled(analytics, true);
};

export const disableAnalytics = () => {
  analytics.app.automaticDataCollectionEnabled = false;
  setAnalyticsCollectionEnabled(analytics, false);
};

export const provider = new TwitterAuthProvider();
provider.setCustomParameters({
  lang: userLang,
});
