import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/store';
import { selectAnalysysCookieConsent, setAnalysysCookieConsent } from './privacySlice';

const AnalyticCookieConsentButton = () => {
  const dispatch = useAppDispatch();
  const handleAgree = () => {
    dispatch(setAnalysysCookieConsent(true));
    window.location.reload();
  };
  const handleDisagree = () => {
    dispatch(setAnalysysCookieConsent(false));
  };

  const analysisCookieConsent = useSelector(selectAnalysysCookieConsent);

  if (analysisCookieConsent) {
    return (
      <Button
        variant="contained"
        onClick={handleDisagree}
      >
        同意
      </Button>
    );
  }
  return (
    <Button
      variant="contained"
      onClick={handleAgree}
      color="error"
    >
      拒否
    </Button>
  );
};

export default AnalyticCookieConsentButton;
