import {
  createContext,
  useContext,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { ServiceUser } from '../../domain/entity/service_user';
import { selectServiceUser } from './authSlice';

// https://reffect.co.jp/react/react-firebase-auth
export const AuthContext = createContext<ServiceUser | null>(null);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: any) => {
  const serviceUser = useSelector(selectServiceUser);
  const authContext = useMemo(() => serviceUser, [serviceUser]);
  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};
