import React, {
  useEffect, useRef,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
} from '@mui/material';

import {
  searchPublicFavorites,
  selectAllFavorites,
  selectFavoriteSearchStatus,
  resetFavoriteList,
  searchPrivateFavorites,
  selectFavoriteFetchStatus,
  setFetchStatus,
  resetSearchRequest,
  resetFetchStatus,
} from './favoriteSlice';
import FavoritePanel from './FavoritePanel';
import { useAuthContext } from '../auth/AuthContext';
import { useAppDispatch } from '../../app/store';

type FavoriteListProps = {
  screenName: string
  query: string
}

export const FavoriteList = (props: FavoriteListProps) => {
  const { screenName, query } = props;
  const dispatch = useAppDispatch();
  const favoriteSearchStatus = useSelector(selectFavoriteSearchStatus);
  const favoriteList = useSelector(selectAllFavorites);
  const fetchStatus = useSelector(selectFavoriteFetchStatus);
  const serviceUser = useAuthContext();

  const ref = useRef<HTMLDivElement>(null);

  // 表示しテールページの依存情報が更新される場合、表示しているリストをリセットして再取得する
  useEffect(() => {
    dispatch(resetFavoriteList());
    dispatch(resetSearchRequest());
    dispatch(resetFetchStatus());
    if (favoriteSearchStatus === 'idle') {
      if (serviceUser !== null
        && screenName === serviceUser.user.screenName) {
        dispatch(searchPrivateFavorites(query));
      } else {
        dispatch(searchPublicFavorites({ screenName, query }));
      }
    }
  }, [
    // screenNameが変更されたらリストを更新
    screenName,
    // クエリが変更されたらリストを更新
    query,
    // ログイン状態が変更されたらリストを更新
    serviceUser !== null,
  ]);

  // 新しいツイートを読み込む場合、読み込みが成功したらリストを取得する
  useEffect(() => {
    dispatch(resetFavoriteList());
    dispatch(resetSearchRequest());
    if (favoriteSearchStatus === 'idle' && fetchStatus === 'succeeded') {
      if (serviceUser !== null
        && screenName === serviceUser.user.screenName) {
        dispatch(searchPrivateFavorites(query));
      } else {
        dispatch(searchPublicFavorites({ screenName, query }));
      }
      dispatch(setFetchStatus('idle'));
    }
  }, [
    // 読み込みボタンが押されたあと、読み取りが完了したらリストを更新するため
    fetchStatus,
  ]);

  // 新しいページをロードする処理
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && favoriteSearchStatus === 'idle') {
          if (serviceUser !== null
            && screenName === serviceUser.user.screenName) {
            dispatch(searchPrivateFavorites(query));
          } else {
            dispatch(searchPublicFavorites({ screenName, query }));
          }
        }
      },
    );
    if (ref.current === null) return () => { };
    observer.observe(ref.current);
    return () => {
      if (ref.current === null) return;
      observer.unobserve(ref.current);
    };
  }, [
    // リストの更新状態が変更されたら、場合によってはリストを更新するため
    favoriteSearchStatus,
  ]);

  let content;
  if (favoriteSearchStatus === 'idle' || favoriteSearchStatus === 'loading') {
    if (favoriteList.length === 0) {
      content = (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    } else {
      const list = favoriteList
        .slice()
        .sort((a, b) => b.tweet.createdAt - a.tweet.createdAt)
        .map(
          (record) => (
            <div key={`favorite-list-${record.tweet.id}-${record.user.id}-div`}>
              <FavoritePanel
                favorite={record}
              />
              <Divider />
            </div>
          ),
        );
      content = (
        <div>
          {list}
        </div>
      );
    }
  } else if (favoriteSearchStatus === 'succeeded') {
    const list = favoriteList
      .slice()
      .sort((a, b) => b.tweet.createdAt - a.tweet.createdAt)
      .map(
        (record) => (
          <div key={`favorite-list-${record.tweet.id}-${record.user.id}-div`}>
            <FavoritePanel
              favorite={record}
            />
            <Divider />
          </div>
        ),
      );
    content = (
      <div>
        {list}
      </div>
    );
  } else if (favoriteSearchStatus === 'failed') {
    content = 'error';
  }

  let loadingButton;
  if (favoriteSearchStatus === 'idle' || favoriteSearchStatus === 'loading') {
    loadingButton = (
      <Button variant="text" fullWidth disabled>
        {favoriteList.length}
        &nbsp;found Loading...
      </Button>
    );
  } else if (favoriteSearchStatus === 'succeeded') {
    loadingButton = (
      <Button variant="text" fullWidth>
        {favoriteList.length}
        &nbsp;found
      </Button>
    );
  } else {
    // complete
    loadingButton = (
      <Button variant="text" fullWidth disabled>
        {favoriteList.length}
        &nbsp;found
      </Button>
    );
  }

  return (
    <>
      {content}
      <div ref={ref}>
        {loadingButton}
      </div>
    </>
  );
};

export default FavoriteList;
