import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserRepository from '../../api/userRepository';
import { AppDispatch, RootState } from '../../app/store';
import { ApiStatus } from '../../domain/entity/api-status';
import { ServiceUser } from '../../domain/entity/service_user';

export type UserSearchState = {
  autoCompleteStatus: ApiStatus
  serviceUsers: ServiceUser[]
  error: string | null,
};

const initialState: UserSearchState = {
  autoCompleteStatus: 'idle',
  serviceUsers: [],
  error: null,
};

export const selectServiceUsers = (state: RootState) => state.userSerch.serviceUsers;

export const autoCompleteScreenName = createAsyncThunk<
  ServiceUser[],
  string,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'users/autocomplete',
  async (screenName) => {
    const response = await UserRepository.screenNameAutocomplete(screenName);
    return response;
  },
);

const userSerchSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(autoCompleteScreenName.pending, (state): void => {
        state.autoCompleteStatus = 'loading';
      })
      .addCase(
        autoCompleteScreenName.fulfilled,
        (state, action) => {
          state.serviceUsers = action.payload;
          state.autoCompleteStatus = 'succeeded';
        },
      )
      .addCase(
        autoCompleteScreenName.rejected,
        (state) => {
          state.autoCompleteStatus = 'failed';
        },
      );
  },
});

export default userSerchSlice.reducer;
