import { AlternateEmail, Search } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from '@mui/material';
import React, {
  FormEvent,
  useState,
} from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/store';
import { ServiceUser } from '../../domain/entity/service_user';
import { autoCompleteScreenName, selectServiceUsers } from './userSearchSlice';

const UserSearchForm = () => {
  const [inputScreenName, setInputScreenName] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onInputScreenNameChange = (newInputScreenName: string) => {
    dispatch(autoCompleteScreenName(newInputScreenName));
    setInputScreenName(newInputScreenName);
  };

  const handleOnFocus = () => {
    dispatch(autoCompleteScreenName(''));
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inputScreenName) {
      navigate(`/users/${inputScreenName}`);
    }
  };

  const serviceUsers = useSelector(selectServiceUsers);

  return (
    <Box component="section" m={1}>
      <Paper
        component="form"
        onSubmit={onSubmit}
      >
        <Grid
          container
          alignItems="center"
        >
          <Grid item xs={10} sm={11}>
            <Autocomplete
              id="country-select-demo"
              sx={{ width: '100%' }}
              options={serviceUsers}
              disablePortal
              autoComplete
              fullWidth
              inputValue={inputScreenName}
              onInputChange={(event: any, newInputValue: string) => {
                onInputScreenNameChange(newInputValue);
              }}
              onFocus={handleOnFocus}
              isOptionEqualToValue={(option, value) => option.user.id === value.user.id}
              getOptionLabel={(serviceUser: ServiceUser) => serviceUser.user.screenName}
              filterOptions={(x) => x}
              renderOption={(props, serviceUser: ServiceUser) => (
                <Box
                  component="li"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={serviceUser.user.profileImageURL}
                    alt=""
                  />
                  {serviceUser.user.screenName}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="ユーザ名"
                  InputProps={{
                    ...params.InputProps,
                    type: 'text',
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmail />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sm={1}
          >
            <IconButton type="submit" aria-label="search">
              <Search />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default UserSearchForm;
