import {
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import favoriteReducer from '../features/favorites/favoriteSlice';
import authReducer from '../features/auth/authSlice';
import appBarReducer from '../features/app-bar/appBarSlice';
import privacyReducer from '../features/privacy-policy/privacySlice';
import userSearchReducer from '../features/user-search/userSearchSlice';

export const history = createBrowserHistory();

const reducer = combineReducers({
  router: connectRouter(history),
  favorites: favoriteReducer,
  auth: authReducer,
  appBar: appBarReducer,
  privacy: privacyReducer,
  userSerch: userSearchReducer,
});

export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();

export const store = configureStore({
  reducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware().concat(routerMiddleware(history));
  },
});
