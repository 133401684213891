import { Search } from '@mui/icons-material';
import { Grid, IconButton, TextField } from '@mui/material';
import { ChangeEvent, FormEvent, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

type SearchFormProps = {
  screenName: string
  query: string
};

const SearchForm = (props: SearchFormProps) => {
  const { screenName, query } = props;
  const [queryInput, setQueryInput] = useState(query);

  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQueryInput(event.target.value);
  };

  const submitSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate(
      {
        pathname: `/users/${screenName}`,
        search: `?${createSearchParams({ query: queryInput })}`,
      },
    );
  };

  return (
    <form onSubmit={submitSearch}>
      <Grid container>
        <Grid item xs={11}>
          <TextField
            fullWidth
            variant="standard"
            label={`@${screenName}さんのお気に入りを検索する`}
            id="search"
            value={queryInput}
            onChange={handleChange}
            placeholder="検索"
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton type="submit" aria-label="search">
            <Search />
          </IconButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default SearchForm;
