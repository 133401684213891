import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../app/store';
import {
  fetchFavorites,
  selectFavoriteFetchStatus,
} from './favoriteSlice';

const LoadFavoritesButton = () => {
  const dispatch = useAppDispatch();

  const fetchStatus = useSelector(selectFavoriteFetchStatus);

  const handleClick = () => {
    dispatch(fetchFavorites());
  };

  if (fetchStatus === 'loading') {
    return (
      <Button variant="text" fullWidth disabled>
        読み込み中
      </Button>
    );
  }
  return (
    <Button variant="text" fullWidth onClick={handleClick}>
      いいねを読み込む
    </Button>
  );
};

export default LoadFavoritesButton;
