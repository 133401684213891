import {
  Box,
  Container,
  Paper,
  Typography,
  Link,
} from '@mui/material';

const ContactPage = () => (
  <Box component="section">
    <Container maxWidth="sm" disableGutters>
      <Paper>
        <Box p={4}>
          <Box my={2}>
            <Typography
              variant="h3"
              component="h1"
            >
              お問い合わせ先
            </Typography>
            <Box my={2}>
              <Typography
                variant="h4"
                component="h2"
              >
                問い合わせの前に
              </Typography>
              <ul>
                <li>Twitterでのユーザからの問い合わせはDMでのみ受け付けます。（リプライ等には応じません。）</li>
                <li>個別に発生していると思われる問題については、個別対応は行ないません。</li>
                <li>emailからのお問い合わせでは対応に必要な情報をお尋ねすることがあります。</li>
              </ul>
            </Box>
            <Box my={2}>
              <Typography
                variant="h4"
                component="h2"
              >
                問い合わせ先
              </Typography>
              <ul>
                <li>
                  Twitterアカウント
                  <Link href="https://twitter.com/fav_search">@fav_search</Link>
                  のDMまでどうぞ
                </li>
                <li>
                  email:
                  <Link href="mailto:favsearch.net@gmail.com">favsearch.net@gmail.com</Link>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  </Box>
);

export default ContactPage;
