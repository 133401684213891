import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PrivacyStatus } from '../../domain/entity/privacy-status';
import { selectAnalysysCookieConsent } from './privacySlice';

export const PrivacyContext = createContext<PrivacyStatus | null>(null);

export const usePrivacyContext = () => useContext(PrivacyContext);

const PrivacyProvider = ({ children }: any) => {
  const analysisCookieConsent = useSelector(selectAnalysysCookieConsent);
  const privacyContext = useMemo(
    () => ({ analysis: analysisCookieConsent }),
    [analysisCookieConsent],
  );

  return <PrivacyContext.Provider value={privacyContext}>{children}</PrivacyContext.Provider>;
};

export default PrivacyProvider;
