import { ReactNodeArray } from 'react';
import {
  Avatar,
  Box,
  Button,
  CardActions,
  CardMedia,
  Grid,
  Typography,
  Link as MUILink,
  Card,
} from '@mui/material';
import reactStringReplace from 'react-string-replace';
import { Link } from 'react-router-dom';
import TwitterIcon from '@mui/icons-material/Twitter';
import { compatibleMediaUrl, Medium } from '../../domain/entity/medium';
import { Favorite } from '../../domain/entity/favorite';
import TwitterDate from '../../components/TwitterDate';
import { Url } from '../../domain/entity/url';
import OpenGraphCard from './OpenGraphCard';

type FavoriteProps = {
  favorite: Favorite
}

const FavoritePanel = ({ favorite }: FavoriteProps) => {
  const { tweet } = favorite;
  const media = Array.from(
    new Set(tweet.media.map((medium: Medium) => compatibleMediaUrl(medium))),
  ).map((url: string) => (
    <Box m={1} key={`media-image-box-${tweet.id}-${url}`}>
      <Card>
        <CardMedia
          component="img"
          image={url}
          alt="画像が取得できませんでした"
          loading="lazy"
        />
      </Card>
    </Box>
  ));

  const urlCard = tweet.urls.map((url: Url) => {
    if (!url.openGraph) {
      return '';
    }

    const { openGraph } = url;
    if (openGraph.title === '' && openGraph.imageUrl === '' && openGraph.description === '') {
      return '';
    }

    return (
      <Box m={1} key={`opg-${tweet.id}-${openGraph.url}`}>
        <OpenGraphCard url={url} />
      </Box>
    );
  });

  let { text }: { text: string | ReactNodeArray } = tweet;

  tweet.media.forEach((medium: Medium) => {
    text = reactStringReplace(
      text,
      medium.url,
      (match) => (<MUILink key={`media-${tweet.id}-${match}`} href={medium.expandedUrl}>{medium.displayUrl}</MUILink>),
    );
  });

  tweet.urls.forEach((url: Url) => {
    text = reactStringReplace(
      text,
      url.url,
      (match) => (<MUILink key={`url-${tweet.id}-${match}`} href={url.expandedUrl}>{url.displayUrl}</MUILink>),
    );
  });

  // 旧バージョンのメディア対応。旧バージョンのメディアはURLにMediaURLが入っているので、本文のURLをリンクにできない。
  // そこで、ショートリンクをリンクにレンダリングする処理が必要
  text = reactStringReplace(
    text,
    /(https:\/\/t.co\/\w+)/,
    (match) => (
      <MUILink key={`media-${tweet.id}-${match}`} href={match}>
        {match}
      </MUILink>
    ),
  );

  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={2}>
          <Link
            style={{ textDecoration: 'none', color: 'inherit' }}
            to={`/users/${tweet.user.screenName}`}
          >
            <Avatar sx={{ width: '80%', height: 'auto' }} alt={`${tweet.user.screenName}`} src={tweet.user.profileImageURL} />
          </Link>
        </Grid>
        <Grid item xs={10}>
          <Link
            to={`/users/${tweet.user.screenName}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            {tweet.user.name}
          </Link>
          {' '}
          <Link
            to={`/users/${tweet.user.screenName}`}
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            @
            {tweet.user.screenName}
          </Link>
          {' '}
          <TwitterDate unixTimestamp={tweet.createdAt} />
          <Typography
            variant="body1"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {text}
          </Typography>
          {media}
          {urlCard}
        </Grid>
        <Grid item xs={12}>
          <CardActions disableSpacing>
            <Button
              sx={{ m: 1 }}
              href={`https://twitter.com/${tweet.user.screenName}/status/${tweet.id}`}
              startIcon={<TwitterIcon />}
              size="small"
              variant="outlined"
            >
              Twitter
            </Button>
          </CardActions>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FavoritePanel;
