import { Button } from '@mui/material';
import { signOut } from 'firebase/auth';
import { useAppDispatch } from '../../app/store';
import { auth } from '../../firebase';
import { setError, signOutUser } from './authSlice';

const SignOutButton = () => {
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        dispatch(signOutUser());
      })
      .catch(() => {
        dispatch(setError('サインアウトに失敗しました'));
      });
  };
  return (
    <Button color="inherit" onClick={handleSignOut}>Sign Out</Button>
  );
};

export default SignOutButton;
