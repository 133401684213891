import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Link as MUILink,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContext';
import DeleteUserButton from '../auth/DeleteUserButton';
import UserProtectedButton from '../auth/UserProtectedButton';
import AnalyticCookieConsentButton from '../privacy-policy/AnalyticCookieConsentButton';

const SettingPage = () => {
  const serviceUser = useAuthContext();

  const contentList = [];

  contentList.push(
    (
      <div key="cookie-div">
        <Box m={2} key="cookie-box">
          <Grid container key="cookie-grid-container">
            <Grid item xs={12} sm={12} key="cookie-grid-item-header">
              <Typography
                variant="h5"
                component="h4"
                key="cookie-header"
              >
                クッキーに関する設定
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} key="func-cookie-grid-item">
              <Typography
                variant="h6"
                component="h5"
                key="func-cookie-header"
              >
                機能性クッキーに関する設定
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
              key="func-cookie-button-grid"
            >
              <Button key="func-cookie-button" color="primary" variant="contained" disabled>
                同意
              </Button>
            </Grid>
            <Grid item xs={12} key="func-cookie-caption-grid">
              <Typography
                variant="body2"
                key="func-cookie-caption"
              >
                機能性クッキーはサービスの提供、および、セキュリティのためクッキーの利用は必須となります。
                クッキーに関する詳細は
                <Link key="func-cookie-caption-link" to="/privacy-policy">
                  プライバシーポリシー
                </Link>
                をご確認ください。
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8} key="a-cookie-grid-item">
              <Typography
                variant="h6"
                component="h5"
                key="a-cookie-header"
              >
                分析クッキーに関する設定
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
              }}
              key="a-cookie-button-grid"
            >
              <AnalyticCookieConsentButton key="a-cookie-button" />
            </Grid>
            <Grid item xs={12} key="a-cookie-caption-grid">
              <Typography
                variant="body2"
                key="a-cookie-caption"
              >
                分析クッキーの利用について選択することができます。
                クッキーに関する詳細は
                <Link to="/privacy-policy" key="a-cookie-caption-link">
                  プライバシーポリシー
                </Link>
                をご確認ください。
              </Typography>
            </Grid>
            <Grid item xs={12} key="ads-cookie-grid-item">
              <Typography
                variant="h6"
                component="h5"
                key="ads-cookie-header"
              >
                マーケティングクッキーに関する設定
              </Typography>
            </Grid>
            <Grid item xs={12} key="ads-cookie-caption-grid">
              <Typography variant="body1" key="ads=cookie-caption">
                第三者配信の広告サービス「Google Adsense グーグルアドセンス」で利用されるクッキーです。
                <MUILink key="ads-cookie-caption-link" href="https://policies.google.com/technologies/partner-sites">
                  GOOGLE のサービスを使用するサイトやアプリから収集した情報の GOOGLE による使用
                </MUILink>
                からクッキーの利用について管理することができます。
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider key="cookie-divider" />
      </div>
    ),
  );

  if (serviceUser !== null) {
    contentList.push(
      (
        <div key="public">
          <Box m={2} key="public-box">
            <Grid container key="public-grid-container">
              <Grid item xs={12} sm={8} key="public-grid-item">
                <Typography
                  variant="h5"
                  component="h4"
                  key="public-header"
                >
                  ツイートやお気に入りの公開
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
                key="public-button"
              >
                <UserProtectedButton />
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              key="public-caption"
            >
              お気に入りやツイートの公開設定を変更することができます。
              <br />
              「公開」にした場合、他のユーザもFavSearchであなたのお気に入りを検索することができ、
              他のユーザの検索結果にあなたのツイートが表示されます。
              <br />
              Twitterのアカウントが非公開である場合は「公開」にすることはできません。
            </Typography>
          </Box>
          <Divider key="public-divider" />
        </div>
      ),
    );

    contentList.push(
      (
        <div key="delete-user">
          <Box m={2} key="delete-user-box">
            <Grid container key="delete-user-grid-container">
              <Grid item xs={12} sm={8} key="delete-user-grid-item">
                <Typography
                  variant="h5"
                  component="h4"
                  key="delete-user-header"
                >
                  保存されたデータを削除する
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
                key="delete-user-button"
              >
                <DeleteUserButton />
              </Grid>
            </Grid>
            <Typography
              variant="body2"
              key="delete-user-caption"
            >
              FavSearchに保存されているユーザ情報、お気に入り情報を削除します。
            </Typography>
          </Box>
          <Divider key="public-divider" />
        </div>
      ),
    );
  }

  return (
    <Box component="section" mb={4}>
      <Container maxWidth="sm" disableGutters>
        <Paper>
          <Box p={2}>
            {contentList}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default SettingPage;
