import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { RootState } from '../../app/store';
import { disableAnalytics, enableAnalytics } from '../../firebase';

export const currentConsentVersion = 'v2';

export type PrivacyState = {
  checkConsent: boolean,
  consentVersion: string | null,
  analysisCookieConsent: boolean,
  error: string | undefined | null
}

const initialState: PrivacyState = {
  checkConsent: false,
  consentVersion: null,
  analysisCookieConsent: false,
  error: null,
};

export const selectHasConsent = (state: RootState) => !(
  state.privacy.checkConsent
  && state.privacy.consentVersion === currentConsentVersion
);

export const selectAnalysysCookieConsent = (
  state: RootState,
) => state.privacy.analysisCookieConsent;

export const selectCheckConsent = (
  state: RootState,
) => state.privacy.checkConsent;

export const selectConsentVersion = (
  state: RootState,
) => state.privacy.consentVersion;

const privacySlice = createSlice({
  name: 'privacy',
  initialState,
  reducers: {
    setCheckConsent: (state: PrivacyState, action: PayloadAction<boolean>): void => {
      const checkConsent = action.payload;
      if (checkConsent) {
        Cookies.set('consent', '1', { expires: 360, path: '/' });
      } else {
        Cookies.set('consent', '0', { expires: 360, path: '/' });
      }

      state.checkConsent = checkConsent;
    },
    setConsentVersion: (state: PrivacyState, action: PayloadAction<string | null>): void => {
      const consentVersion = action.payload;
      if (consentVersion) {
        Cookies.set('consent_version', currentConsentVersion, { expires: 360, path: '/' });
      }

      state.consentVersion = action.payload;
    },
    setAnalysysCookieConsent: (state: PrivacyState, action: PayloadAction<boolean>): void => {
      const analysisCookieConsent = action.payload;
      if (analysisCookieConsent) {
        Cookies.set('a_consent', '1', { expires: 360, path: '/' });
        enableAnalytics();
      } else {
        Cookies.set('a_consent', '0', { expires: 360, path: '/' });
        disableAnalytics();
      }
      state.analysisCookieConsent = analysisCookieConsent;
    },
  },
});

export const {
  setCheckConsent,
  setConsentVersion,
  setAnalysysCookieConsent,
} = privacySlice.actions;

export default privacySlice.reducer;
