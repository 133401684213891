import {
  createAsyncThunk, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { FavoriteRepository } from '../../api/favoriteRepository';
import { AppDispatch, RootState } from '../../app/store';
import { ApiStatus } from '../../domain/entity/api-status';
import { Favorite } from '../../domain/entity/favorite';
import { FavoriteListPage } from '../../domain/entity/favorite-list';

export type FavoritesState = {
  page: number
  favorites: Favorite[]
  searchStatus: ApiStatus
  searchCurrentRequestId: string | undefined
  fetchStatus: ApiStatus
  fetchCurrentRequestId: string | undefined
  error: string | undefined | null
}

const initialState: FavoritesState = {
  page: 0,
  favorites: [],
  searchStatus: 'idle',
  searchCurrentRequestId: undefined,
  fetchStatus: 'idle',
  fetchCurrentRequestId: undefined,
  error: null,
};

export const selectAllFavorites = (state: RootState) => state.favorites.favorites;
export const selectFavoriteSearchStatus = (state: RootState) => state.favorites.searchStatus;
export const selectFavoriteFetchStatus = (state: RootState) => state.favorites.fetchStatus;
export const selectFaviruteError = (state: RootState) => state.favorites.error;
export const selectPage = (state: RootState) => state.favorites.page;

export type PublicSearchArgs = {
  screenName: string
  query: string
};

export const searchPublicFavorites = createAsyncThunk<
  FavoriteListPage,
  PublicSearchArgs,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'favorites/search',
  async (args, { getState }) => {
    const { screenName, query } = args;
    const state = getState();
    const page = selectPage(state);

    const response = await FavoriteRepository.getPublicFavoriteList(
      screenName,
      query,
      page,
      20,
    );
    return response;
  },
);

export const searchPrivateFavorites = createAsyncThunk<
  FavoriteListPage,
  string,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'favorites/search/private',
  async (query, { getState }) => {
    const state = getState();
    const page = selectPage(state);

    const response = await FavoriteRepository.getPrivateFavoriteList(
      query,
      page,
      20,
    );
    return response;
  },
);

export const fetchFavorites = createAsyncThunk<
  {},
  void,
  {
    dispatch: AppDispatch,
    state: RootState,
  }
>(
  'favorites/fetch',
  async () => {
    const response = await FavoriteRepository.fetchFavoriteList();
    return response;
  },
);

const favoriteSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    resetFavoriteList: (state: FavoritesState) => {
      state.favorites = [];
      state.page = 0;
      state.error = null;
    },
    resetSearchRequest: (state: FavoritesState) => {
      state.searchStatus = 'idle';
      state.searchCurrentRequestId = undefined;
    },
    resetFetchStatus: (state: FavoritesState) => {
      state.fetchStatus = 'idle';
      state.fetchCurrentRequestId = undefined;
    },
    setFetchStatus: (state: FavoritesState, action: PayloadAction<ApiStatus>) => {
      state.fetchStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPublicFavorites.pending, (state, action): void => {
        if (state.searchStatus === 'idle') {
          state.searchStatus = 'loading';
          state.searchCurrentRequestId = action.meta.requestId;
        }
      })
      .addCase(
        searchPublicFavorites.fulfilled,
        (state, action) => {
          const { requestId } = action.meta;
          if (
            state.searchStatus === 'loading'
            && state.searchCurrentRequestId === requestId
          ) {
            state.page = action.payload.page + 1;
            state.favorites.push(...action.payload.favoriteList);
            state.searchStatus = 'idle';
            state.searchCurrentRequestId = undefined;
            if (action.payload.hasNext) {
              state.searchStatus = 'idle';
            } else {
              state.searchStatus = 'succeeded';
            }
          }
        },
      )
      .addCase(
        searchPublicFavorites.rejected,
        (state, action) => {
          const { requestId } = action.meta;
          if (
            state.searchStatus === 'loading'
            && state.searchCurrentRequestId === requestId
          ) {
            state.searchStatus = 'failed';
            state.error = action.error.message;
            state.searchCurrentRequestId = undefined;
          }
        },
      )
      .addCase(searchPrivateFavorites.pending, (state, action): void => {
        if (
          state.searchStatus === 'idle'
          && state.searchCurrentRequestId === undefined
        ) {
          state.searchStatus = 'loading';
          state.searchCurrentRequestId = action.meta.requestId;
        }
      })
      .addCase(
        searchPrivateFavorites.fulfilled,
        (state, action) => {
          const { requestId } = action.meta;
          if (
            state.searchCurrentRequestId === requestId
            && state.searchStatus === 'loading'
          ) {
            state.page = action.payload.page + 1;
            state.favorites.push(...action.payload.favoriteList);
            state.searchCurrentRequestId = undefined;
            if (action.payload.hasNext) {
              state.searchStatus = 'idle';
            } else {
              state.searchStatus = 'succeeded';
            }
          }
        },
      )
      .addCase(
        searchPrivateFavorites.rejected,
        (state, action) => {
          const { requestId } = action.meta;
          if (
            state.searchStatus === 'loading'
            && state.searchCurrentRequestId === requestId
          ) {
            state.searchStatus = 'failed';
            state.error = action.error.message;
            state.searchCurrentRequestId = undefined;
          }
        },
      )
      .addCase(fetchFavorites.pending, (state, action): void => {
        if (
          state.fetchStatus === 'idle'
          && state.fetchCurrentRequestId === undefined
        ) {
          state.fetchStatus = 'loading';
          state.fetchCurrentRequestId = action.meta.requestId;
        }
      })
      .addCase(
        fetchFavorites.fulfilled,
        (state, action) => {
          const { requestId } = action.meta;
          if (
            state.fetchCurrentRequestId === requestId
            && state.fetchStatus === 'loading'
          ) {
            state.fetchStatus = 'succeeded';
            state.fetchCurrentRequestId = undefined;
          }
        },
      )
      .addCase(
        fetchFavorites.rejected,
        (state, action) => {
          const { requestId } = action.meta;
          if (
            state.fetchStatus === 'loading'
            && state.fetchCurrentRequestId === requestId
          ) {
            state.searchStatus = 'failed';
            state.error = action.error.message;
            state.searchCurrentRequestId = undefined;
          }
        },
      );
  },
});

export const {
  resetFavoriteList,
  resetSearchRequest,
  resetFetchStatus,
  setFetchStatus,
} = favoriteSlice.actions;

export default favoriteSlice.reducer;
