import { Group, SaveAlt, Search } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  createTheme,
  Grid,
  styled,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../auth/AuthContext';
import SignInButton from '../auth/SignInButton';

const BackgroudImageBox = styled(Grid)({
  background: 'linear-gradient(to right bottom, #2196f3, #673ab7)',
  backgroundSize: 'cover',
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#03a9f4',
      contrastText: '#fff',
    },
  },
});

const TopPage = () => {
  let mainButton;

  const serviceUser = useAuthContext();
  const navigate = useNavigate();

  if (serviceUser === null) {
    mainButton = (
      <SignInButton
        variant="contained"
        size="large"
        color="primary"
      />
    );
  } else {
    mainButton = (
      <Button
        variant="contained"
        size="large"
        color="primary"
        onClick={() => { navigate(`/users/${serviceUser.user.screenName}`); }}
      >
        お気に入りを検索する
      </Button>
    );
  }

  return (
    <Box id="main" mb={4}>
      <ThemeProvider theme={darkTheme}>
        <BackgroudImageBox
          container
          direction="column"
          alignItems="center"
          style={{ minHeight: '100vh' }}
          justifyContent="center"
        >
          <Grid item xs={3}>
            <Container maxWidth="sm">
              <Typography
                component="h1"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
                mt="6vh"
              >
                FavSearch
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
                mt="1vh"
              >
                Twitterの「いいね」を保存して
                <br />
                検索できるようにするサービスです。
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                align="center"
              >
                いいねしたツイートが見つからなかったことがありませんか？
                <br />
                FavSearchにいいねしたツイートを保存すれば、
                <br />
                いつでも見つけられるようになります。
              </Typography>
              <Box
                component="div"
                textAlign="center"
                mt="5vh"
              >
                {mainButton}
              </Box>
              <Box
                component="div"
                textAlign="center"
                mt="5vh"
              >
                <Button
                  variant="contained"
                  size="large"
                  color="inherit"
                  onClick={() => { navigate('/users/fav_search_dev?query=もふもふ'); }}
                >
                  ログインせずに試してみる
                </Button>
              </Box>
            </Container>
          </Grid>
        </BackgroudImageBox>
      </ThemeProvider>
      <Grid container>
        <Grid item xs={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography
                variant="h4"
                component="div"
                align="center"
              >
                <SaveAlt sx={{ fontSize: 100 }} />
              </Typography>
              <Typography
                sx={{ mb: 2 }}
                color="text.secondary"
                align="center"
              >
                Save
              </Typography>
              <Typography
                align="center"
                variant="body2"
              >
                Twitterのお気に入りを
                <br />
                FavSearchに
                <br />
                取り込むことができます。
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography
                variant="h4"
                component="div"
                align="center"
              >
                <Search sx={{ fontSize: 100 }} />
              </Typography>
              <Typography
                sx={{ mb: 2 }}
                color="text.secondary"
                align="center"
              >
                Search
              </Typography>
              <Typography
                align="center"
                variant="body2"
              >
                取り込んだTwitterのお気に入りを
                <br />
                自由に検索することができます。
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ height: '100%' }}>
            <CardContent>
              <Typography
                variant="h4"
                component="div"
                align="center"
              >
                <Group sx={{ fontSize: 100 }} />
              </Typography>
              <Typography
                sx={{ mb: 2 }}
                color="text.secondary"
                align="center"
              >
                Discover
              </Typography>
              <Typography
                align="center"
                variant="body2"
              >
                FavSearchを利用している他のユーザの
                <br />
                お気に入りを閲覧・検索できます。
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopPage;
