import {
  Box,
  Container,
  Paper,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

const TermOfServicePage = () => (
  <Box component="section">
    <Container maxWidth="sm" disableGutters>
      <Paper>
        <Box p={4}>
          <Box my={2}>
            <Typography
              variant="h3"
              component="h1"
            >
              利用規約
            </Typography>
            <Typography
              variant="body1"
            >
              この利用規約（以下、「本規約」といいます。）は、このウェブサイト上で提供するサービス（以下、「本サービス」といいます。）の利用条件を定めるものです。
              登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本規約に従って本サービスをご利用いただきます。
            </Typography>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              適用
            </Typography>
            <Box m={2}>
              <Typography
                variant="body1"
              >
                本規約は、ユーザーと本サービスの利用に関わる一切の関係に適用されるものとします。
              </Typography>
            </Box>
            <Box m={2}>
              <Typography
                variant="body1"
              >
                本サービスに関し、本規約のほかご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。
                これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。
              </Typography>
            </Box>
            <Box m={2}>
              <Typography
                variant="body1"
              >
                本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。
              </Typography>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              個人情報の取扱い
            </Typography>
            <Box m={2}>
              <Typography variant="body1">
                本サービスの利用によって取得する個人情報については、「
                <Link to="/privacy-policy">プライバシーポリシー</Link>
                」に従い適切に取り扱うものとします。
              </Typography>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              本サービスの提供の停止等
            </Typography>
            <Box m={2}>
              <Typography variant="body1">
                以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
              </Typography>
              <ul>
                <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                <li>地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
                <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                <li>その他、当社が本サービスの提供が困難と判断した場合</li>
              </ul>
            </Box>
            <Box m={2}>
              <Typography variant="body1">
                本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。
              </Typography>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              利用制限および登録抹消
            </Typography>
            <Box m={2}>
              <Typography variant="body1">
                ユーザーが以下のいずれかに該当する場合には、事前の通知なくユーザーに対して、本サービスの全部もしくは一部の利用を制限し、
                またはユーザーとしての登録を抹消することができるものとします。
              </Typography>
              <ul>
                <li>本規約のいずれかの条項に違反した場合</li>
                <li>登録事項に虚偽の事実があることが判明した場合</li>
                <li>本サービスの運営者からの連絡に対し、一定期間返答がない場合</li>
                <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
                <li>その他、当社が本サービスの利用を適当でないと判断した場合</li>
              </ul>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              退会
            </Typography>
            <Box m={2}>
              <Typography variant="body1">
                ユーザーは，当社の定める退会手続により，本サービスから退会できるものとします。
              </Typography>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              保証の否認および免責事項
            </Typography>
            <Box m={2}>
              <Typography variant="body1">
                本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、
                エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
              </Typography>
            </Box>
            <Box m={2}>
              <Typography variant="body1">
                本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。
                ただし、本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合、この免責規定は適用されません。
              </Typography>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              サービス内容の変更等
            </Typography>
            <Box m={2}>
              <Typography variant="body1">
                本サービスのサービス内容・仕様は運営者の判断で予告無く変更される可能性があります。
              </Typography>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              利用規約の変更
            </Typography>
            <Box m={2}>
              <Typography variant="body1">
                必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。
                変更後の利用規約は、本ウェブサイトに掲載したときから効力を生じるものとします。
              </Typography>
            </Box>
          </Box>
          <Box my={2}>
            <Typography
              variant="h4"
              component="h2"
            >
              問い合わせ先について
            </Typography>
            <Box m={2}>
              <Typography variant="body1">
                本サービスに関する問い合わせは「
                <Link to="/contact">問い合わせページ</Link>
                」に記載している方法のみで受け付けています。
                問い合わせ時の注意事項等守って頂けてないと判断した場合、その後の利用をお断りする事があります。
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  </Box>
);

export default TermOfServicePage;
